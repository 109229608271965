
import { useEffect, useState } from "react";
import {useSelector, useDispatch} from "react-redux";
import { getUserBalance } from "../redux/userSlice";
import { useNavigate } from "react-router-dom";

export default function Auth({content, guestContent, children}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user);
    const [check, setCheck] = useState(false);

    useEffect(() => {
        if(!user?.data?.id){
            if(!check) {
                dispatch(getUserBalance());
            }
            const redirectTo = window.location.pathname.split('/').length > 2 ? 
            window.location.pathname.split('/')[1] : window.location.pathname + 'en'
            if(!guestContent && check) navigate(`${redirectTo}/login`);
        }
        if(!check)setCheck(true);
    },[dispatch, navigate, setCheck, guestContent, user?.data]);

    return(<>
        {user?.data?.id && 
        <>
        {content || children}
        </> 
        // : 
        // <>
        // {guestContent}
        // </>}
        }
    </>)

};
