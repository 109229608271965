import React, {useEffect} from "react";
import {useDispatch , useSelector} from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import useWidth from "../../lib/hooks/useWidth" 

import { getApiList } from '../../redux/apiSlice'
import Button from "../../lib/customBlocks/button/button";
import trueIcon from '../../static/checked.svg'
import styles from "./api.module.scss"

const Api: React.FC<any> = () => { 
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch()
    const [_, isMobile] = useWidth(980);

    const apiList = useSelector((state: any) => state.api).data

    useEffect(() => {
       dispatch(getApiList())
    }, [dispatch])
    
    const getDetail = (apiIndex) => {
        const api = apiList[apiIndex]
        const newPath = location.pathname.replace('api', 'update');
        navigate(`${newPath}?id=${api.ID}`);
    }

    return <div style={{fontSize: 14}}>
    <div className="px-3 py-4 row gy-3"
        style={{borderRadius: '10px'}}>
       <div className="d-flex justify-content-md-end">
            <Button 
            primary
            action={() => navigate(`/${window.location.pathname.split('/')[1]}/create`)}>
            Create Api
            </Button>
        </div>
        {/* Add Mobile case */}
        <div className="d-flex mt-5 p-3 gap-3 justify-content-between" style={{background: '#fff', borderRadius: '10px'}}>
            {apiList?.length && 
              !isMobile ?
            <table className="table">
                <thead>
                    <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Date</th>
                    <th scope="col">Name</th>
                    <th scope="col">Available</th>
                    <th scope="col">Deposit</th>
                    <th scope="col">Withdraw</th>
                    </tr>
                </thead>
                <tbody>
                    {apiList.map((api, i) => 
                    <tr onClick = {() => getDetail(i)}>
                    <td style={{width: '15%'}}>{api.ID}</td>
                    <td style={{width: '25%'}}>{api.createdAt}</td>
                    <td style={{width: '15%'}}>{api.name}</td>
                    <td style={{width: '15%'}}>
                      {api.enabled && <img src={trueIcon} style={{height: '45px'}}/>}
                    </td>
                    <td style={{width: '15%'}}>
                       {api.invoiceActive && <img src={trueIcon} style={{height: '45px'}}/>}
                    </td>
                    <td style={{width: '15%'}}>
                        {api.withdrawActive && <img src={trueIcon} style={{height: '45px'}}/>}
                    </td>
                    </tr>
                    )}
                </tbody>
            </table>
             :
             apiList?.map((api, index) => 
                <div key={index} className={`w-100 ${styles.mobile_block} py-3 px-2 mb-3`} style={{border: '1px solid #272435', borderRadius: 12, background: '#f8f8f8'}}>
                    <div className=''>
                        <ul className={`${styles.api_mobile_list} px-lg-3 px-0 list-unstyled`}>
                            <li className='d-flex align-items-center justify-content-between'>
                                <p>ID</p>
                                <span style={{textAlign: 'end'}}>{api.ID} </span>
                            </li>
                            <li className='d-flex align-items-center justify-content-between'>
                                <p>Date</p>
                                <span>{api.createdAt.replace(/T/, ' ').replace(/\..+/, '')} </span>
                            </li>
                            <li className='d-flex align-items-center justify-content-between'>
                                <p>Name</p>
                                <span>{api.name} </span>
                            </li>
                            <li className='d-flex align-items-center justify-content-between'>
                                <p>Available</p>
                                <span>{api.enabled && <img src={trueIcon}/>} </span>
                            </li>
                            <li className={`d-flex align-items-center justify-content-between`}>
                                <p>Deposit</p>
                                <span>{api.invoiceActive && <img src={trueIcon} />}</span>
                            </li>
                            <li className={`d-flex align-items-center justify-content-between`}>
                                <p>Withdraw</p>
                                <span>{api.withdrawActive && <img src={trueIcon} />}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                )
            }
        </div>
    </div>
</div>
}

export default Api