const coreUrl = process.env.REACT_APP_CORE_URL

const innerUrl = '/api';
const mainUrl = coreUrl + innerUrl;
let lang = 'en'
export const setLeng = (l) => lang = l;

const queryString = (q) => '?' + (new URLSearchParams(q)).toString()

export const urls = {
    // list
    localeGet: () => `${mainUrl}/locale/get`,
    login: () =>`${mainUrl}/client/login`,
    userPasswordChange: () => `${mainUrl}/settings/password/change`,
    userGetBalance: () => `${mainUrl}/client/balance`,
    listApi: () => `${mainUrl}/api/list`,
    apiCreate: () => `${mainUrl}/api/create`,
    apiDetail: () => `${mainUrl}/api/detail`,
    apiSave: () => `${mainUrl}/api/settings`,
    apiUpdate: () => `${mainUrl}/api/whitelist`,
    apiDelete: () => `${mainUrl}/api`,
    reportGenerate: () => `${mainUrl}/order/list/export`,
    listTransactions: () => `${mainUrl}/order/list`,
    listStatuses: () => `${mainUrl}/order/status/list`,
    userLogout: () => `${mainUrl}/client/logout`,
}

export const fetchapp = async (url,options) => {
    return await fetch(url,{
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        ...options
});
}
