// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_head__G171j {
  font-size: 20px;
  font-weight: 600;
  position: fixed;
  top: 4%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  color: #002D64;
  z-index: 1;
  background: #fff;
}`, "",{"version":3,"sources":["webpack://./src/pages/header/header.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,gBAAA;EACA,eAAA;EACA,OAAA;EACA,SAAA;EACA,gCAAA;EACA,YAAA;EACA,cAAA;EACA,UAAA;EACA,gBAAA;AACJ","sourcesContent":[".head {\n    font-size: 20px;\n    font-weight: 600;\n    position: fixed;\n    top:4%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 100vw;\n    color: #002D64;\n    z-index: 1;\n    background: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"head": `header_head__G171j`
};
export default ___CSS_LOADER_EXPORT___;
