import React, { useState } from 'react';
import styles from "./input.module.scss";
import hidePassword from "../../../static/hidePassword.svg"
import close from "../../../static/close.svg";

interface InputProps {
  label?: string;
  placeholder?: string,
  width?: string;
  action?: React.ChangeEventHandler<HTMLInputElement>;
  closeAction?: React.MouseEventHandler<HTMLImageElement>;
  disabled?: boolean;
  readonly?: boolean;
  value?: string;
  error?: boolean;
  errorMessage?: string;
  lg?: boolean,
  sm?: boolean,
  password?: boolean,
  changeDark?: boolean,
  border?: string,
  apiAddress?: boolean,
}

const Input: React.FC<InputProps> = ({ label, placeholder, width, lg, sm, disabled, readonly, value, error, errorMessage, action, closeAction, password, changeDark, border, apiAddress}) => {

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const labelStyles: React.CSSProperties = {
    fontSize: 12,
    fontWeight: 500,
    color: error ? '#F21A1A' : '',
    fontFamily: 'Montserrat',
    marginBottom: 2
  }
  return (
      <div style={{width: width}}>
        {label && <label htmlFor="input-field"
          className="form-label"
          style={labelStyles}>
            {label}
        </label>
         }
          {!password && !apiAddress &&
              <input
                  type="text"
                  className={`form-control ${styles.custom_input} 
                                    ${readonly ? styles.readonly : ''} 
                                    ${disabled && styles.disabled}
                                    ${changeDark && styles.hovered}
                                    ${changeDark && styles.focused}
                                    ${error && 'is-invalid'}`}
                  style={{color: '#fff', height: lg ? '44px' : sm ? 36 : 40, fontSize: 14, border: border || '1px solid grey'}}
                  id="input-field"
                  name="input-field"
                  value={value}
                  onChange={action}
                  placeholder={placeholder || 'Placeholder'}
                  disabled = {disabled}
                  readOnly = {readonly}
              />
          }
           {apiAddress &&
          <div
              className={`d-flex gap-1 pe-4 ps-2 flex-row justify-content-between align-items-center 
                                        ${readonly ? styles.readonly : ''} 
                                        ${disabled && styles.disabled}
                                        ${error && 'is-invalid'}`}
              style={{width: '100%', 
                      color: '#000', 
                      overflow: 'hidden', ...(lg ? {height: '44px'} : {height: 30}), 
                      border: 'none',
                      background: '#d3d9df',
                      borderRadius: '20px'
                    }}
          >
            <input
              className={`${styles.password_input} `}
              
              type='text'
              style={{width: '90%', color: 'black', outline: 'none', background: 'inherit', border: 'none', fontSize: 12, fontWeight: 500}}
              id="input-field"
              name="input-field"
              onChange={action}
              value={value}
              placeholder={placeholder || ''}
              disabled = {disabled}
              readOnly = {readonly}
            />
            <div style={{width: '10%'}}>
              <img src={close} onClick={closeAction}/>
            </div>
          </div>
        }
          {password &&
          <div
              className={`d-flex flex-row justify-content-between align-items-center ${styles.custom_input} 
                                        ${readonly ? styles.readonly : ''} 
                                        ${disabled && styles.disabled}
                                        ${error && 'is-invalid'}`}
              style={{width: '100%', color: '#fff', overflow: 'hidden', ...(lg ? {height: '44px'} : {height: 40})}}
          >
            <input
              className={`${styles.password_input} `}
              
              type={showPassword ? 'text' : 'password'}
              style={{width: '90%', color: 'inherit', outline: 'none', background: 'inherit', border: 'none', ...(lg? {height: '44px'}:{})}}
              id="input-field"
              name="input-field"
              value={value}
              onChange={action}
              placeholder={placeholder || ''}
              disabled = {disabled}
              readOnly = {readonly}
            />
            <div style={{width: '10%'}}>
              <img src={hidePassword} onClick={() => setShowPassword(!showPassword)}/>
            </div>
          </div>
        }

        {error && (
          <div className="invalid-feedback">{errorMessage}</div> 
        )}
      </div>
  );

};

export default Input;
