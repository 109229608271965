import React, {useState, useEffect} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {useDispatch, useSelector } from "react-redux";

import { saveApi, updateApi, deleteGivenApi, getDetailApi } from '../../../redux/apiSlice'

import styles from './update.module.scss';
import Button from "../../../lib/customBlocks/button/button";
import Input from "../../../lib/customBlocks/input/input";
import Plus from "../../../static/plus.svg";
import trueIcon from '../../../static/checked.svg'
import back from "../../../static/backArrow.svg"

const UpdateApi: React.FC<any> = () => { 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const api = useSelector((state: any) => state.api).givenApi;
  const isApiDeleted = useSelector((state: any) => state.api).isDeleted

  const [apiAddresses, setApiAddresses] = useState<string[]>([]);
  const [apiSettings, setApiSettings] = useState<any>({})

  useEffect(() => {
    dispatch(getDetailApi({ID: Number(searchParams.get('id'))}))
  }, [dispatch])

  useEffect(() => {
    setApiAddresses(api?.IPWhiteList);
    setApiSettings({enabled: api?.enabled, deposit: api?.invoiceActive, withdraw: api?.withdrawActive})
  }, [api])

  const handleAddressChange = (index: number, value: string) => {
    const updatedAddresses = [...apiAddresses];
    updatedAddresses[index] = value;
    setApiAddresses(updatedAddresses);
  };

  const handleAddAddress = () => {
    apiAddresses?.length > 0 ? setApiAddresses( [...apiAddresses, ''] ) : setApiAddresses(['']);
  };

  const deleteApi = (apiIndex) => {
    const updatedAddresses = [...apiAddresses];
    updatedAddresses.splice(apiIndex, 1);
    setApiAddresses(updatedAddresses);
  }

  const handleSaveApi = () => {
    dispatch(saveApi({ID: api?.ID, enabled: apiSettings.enabled || api?.enabled, withdrawActive: apiSettings.withdraw, invoiceActive: apiSettings.deposit}))
  }

  const handleUpdateApi = () => {
    dispatch(updateApi({ID: api?.ID, IPWhiteList: apiAddresses}))
  }

  const handleDeleteApi = () => {
        dispatch(deleteGivenApi({ID: api?.ID}))
        isApiDeleted && navigate('/en/home')
  }

    return (
        <div style={{fontSize: 14, background: '#fff', borderRadius: '10px'}}>
        <div className="mt-4 p-5">
                <img onClick = {() => navigate(-1)} src={back} style={{rotate: "180deg", margin: '0 0 30px', width: 15, cursor: "pointer"}}/>
                <div className="mt-5 d-flex flex-column justify-content-between gap-5">
                    <div className="w-100 d-flex flex-column flex-lg-row align-items-start justify-content-between gap-4">
                        <div className="d-flex flex-column gap-4">
                            <div>
                                <span>Available </span> 
                                {api?.enabled && <img src={trueIcon} />}
                            </div>
                            <div>
                            <span>ID: {api?.ID}</span> 
                            </div>
                            <div className="d-flex flex-column">
                                <span>Date of Creation</span>
                                <span>{api?.createdAt?.replace(/T/, ' ').replace(/\..+/, '')}</span>
                            </div>
                            <div>
                                <div className="d-flex flex-column gap-3">
                                    <label>Available API Addresses</label>
                                    <div className="form-control d-flex flex-wrap gap-2 align-items-start w-100" style={{maxWidth: "500px", minWidth: '100px', minHeight: '100px'}}>
                                        {apiAddresses?.map((element, index) => 
                                        <Input value={apiAddresses[index]} 
                                            apiAddress 
                                            placeholder='IP'
                                            sm 
                                            width="80%" 
                                            action={(e) => handleAddressChange(index, e.target.value) } 
                                            closeAction={() => deleteApi(index)}
                                            /> 
                                            )}
                                        <img src={Plus} onClick={handleAddAddress} style={{margin: '0 10px'}}/>
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                        <div className="d-flex flex-column align-items-lg-end align-items-start gap-5">
                            <div>
                                <span>API Name: </span>
                                <span>{` ${api?.name}`}</span>
                            </div>
                            <div className="d-flex flex-column flex-lg-row gap-3 align-items-start align-items-lg-end ">
                                <div className={`${styles.form_container} form-check form-switch`}>
                                    <label className={`form-check-label ${styles.custom_switch_label}`}  htmlFor="invoice">
                                        Deposit
                                    </label>
                                    <input className={`form-check-input ${styles.custom_switch_checkbox}`}  
                                        type="checkbox" 
                                        id="invoice" 
                                        checked={apiSettings?.deposit}
                                        onChange={(e)=> {setApiSettings(p=> ({...p, deposit: e.target.checked}))}}
                                        />
                                </div>
                                <div className={`${styles.form_container} form-check form-switch`}>
                                    <label className={`form-check-label ${styles.custom_switch_label}`}  htmlFor="withdraw">
                                        Withdraw
                                    </label>
                                    <input className={`form-check-input ${styles.custom_switch_checkbox}`} 
                                    type="checkbox" 
                                    id="withdraw" 
                                    checked={apiSettings?.withdraw}
                                    onChange={(e)=> {setApiSettings(p=> ({...p, withdraw: e.target.checked}))}}
                                    />
                                </div>
                                <div className={`${styles.form_container} form-check form-switch`}>
                                    <label className={`form-check-label ${styles.custom_switch_label}`}  htmlFor="onOff">
                                        On/Off
                                    </label>
                                    <input className={`form-check-input ${styles.custom_switch_checkbox}`}  
                                    type="checkbox" 
                                    id="onOff" 
                                    checked={apiSettings?.enabled}
                                    onChange={(e)=> {setApiSettings(p=> ({...p, enable: e.target.checked}))}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-100 d-flex flex-column flex-md-row gap-3">
                        <Button primary action={() => handleSaveApi()}>Save</Button>
                        <Button warning action={() => handleDeleteApi()}>Delete Api</Button>
                        <Button primary action={() => handleUpdateApi()}>Update IP List</Button>
                    </div>
                </div>
        </div>
    </div>
    )
}

export default UpdateApi