import React, {useEffect, useState} from "react";
import {useDispatch, useSelector } from "react-redux";
import cloneDeep from 'lodash/cloneDeep';

import { Validate } from './validate.js'
import styles from "./settings.module.scss"

import { changePassword } from '../../redux/userSlice'
import Button from "../../lib/customBlocks/button/button.tsx";
import Input from "../../lib/customBlocks/input/input.tsx";


const Settings: React.FC<any> = () => { 
    const dispatch = useDispatch();
    const passwordChanged = useSelector((state: any) => state.user.isPasswordChanged);
    const [form, setForm] = useState({old_passwd: null, new_passwd: null, repeat: null })

    const [formErrors, setFormErrors] = useState({
        old_passwd: {
            error: false,
            message: ''
        },
        new_passwd: {
            error: false,
            message: ''
        },
        repeat: {
            error: false,
            message: ''
        },
      });

      useEffect(() => {
        setForm({old_passwd: null, new_passwd: null, repeat: null })
      }, [passwordChanged])

    const submitForm = () => {
        dispatch(changePassword(form))
    }

    const handleInputChange = (value, field) => {
        setForm((p: any)=> ({...p, [value]:field}));
        validateInput(value, field);
      };

    const validateInput = (field, value) => {
        const error = cloneDeep(formErrors[field]);
        Validate(value, field, error, form);
        setFormErrors((prevState) => ({ ...prevState, [field]: error }));
    };

    return (
        <div style={{fontSize: 14}}>
            <div className="px-3 py-4 row gy-3"
                style={{background: '#fff', borderRadius: '10px'}}>
                <h2>Password change</h2>
                <div className="d-flex gap-3 justify-content-between">
                    <Input password  
                        label='Current password' 
                        width="90%" 
                        error={formErrors.old_passwd?.error}
                        errorMessage={formErrors.old_passwd?.message}
                        value={form?.old_passwd || ""}
                        action={(e)=> handleInputChange('old_passwd', e.target.value)}
                        />
                    <Input password 
                        label='New password ?' 
                        width="90%" 
                        error={formErrors.new_passwd?.error}
                        errorMessage={formErrors.new_passwd?.message}
                        value={form?.new_passwd || ""}
                        action={(e)=> handleInputChange('new_passwd', e.target.value)}
                        />
                    <Input password 
                        label='Repeat the password' 
                        width="90%"
                        error={formErrors.repeat?.error}
                        errorMessage={formErrors.repeat?.message}
                        value={form?.repeat || ""}
                        action={(e)=> handleInputChange('repeat', e.target.value)}
                        />
                </div>
                <div className="d-flex justify-content-md-start">
                    <Button 
                        disabled={Object.values(formErrors).some((item) => item.error) || !form?.old_passwd || !form?.new_passwd || !form?.repeat }
                        primary
                        action={() => submitForm()}
                    >
                        Change
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Settings