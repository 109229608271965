// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::-webkit-scrollbar {
  width: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0,55,55,0);
  border: 1px solid rgba(163, 169, 186, 0.5);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA,WAAW;AACX;EACE,2BAA2B;EAC3B,0CAA0C;EAC1C,kBAAkB;AACpB;;AAEA,oBAAoB;AACpB;EACE,gBAAgB;AAClB","sourcesContent":["::-webkit-scrollbar {\n  width: 7px;\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n  background: rgba(0,55,55,0);\n  border: 1px solid rgba(163, 169, 186, 0.5);\n  border-radius: 5px;\n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n  background: #555;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
