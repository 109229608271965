import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { createApi } from "../../../redux/apiSlice";

import styles from './create.module.scss'
import Modal from '../../../lib/Modal.jsx'
import useWidth from "../../../lib/hooks/useWidth"

import Button from "../../../lib/customBlocks/button/button";
import Input from "../../../lib/customBlocks/input/input";
import Plus from "../../../static/plus.svg";
import back from "../../../static/arrow.svg"

const CreateApi: React.FC<any> = ({query}) => { 
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const apiKeys = useSelector((state: any) => state.api)?.data || {};

  const [_, isMobile] = useWidth(768)
  const [apiTitle, setApiTitle] = useState<string>();
  const [apiAddresses, setApiAddresses] = useState<string[]>([]);
  const [validApi, setValidApi] = useState<any>(false)

  const handleAddressChange = (index: number, value: string) => {
    const updatedAddresses = [...apiAddresses];
    updatedAddresses[index] = value;
    setValidApi(validateIPv4(value))
    setApiAddresses(updatedAddresses);
  };

  const handleAddAddress = () => {
    setApiAddresses([...apiAddresses, '']);
  };

  const deleteApi = (apiIndex) => {
    const updatedAddresses = [...apiAddresses];
    updatedAddresses.splice(apiIndex, 1);
    setApiAddresses(updatedAddresses);
  }
  
  const submitForm = () => {
    dispatch(createApi({name: apiTitle, IPWhiteList: apiAddresses}))
    apiKeys && navigate({search: `?scene=apiKeys`})
  }

  const validateIPv4 = (ip) => {
    var ipRegex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return ipRegex.test(ip);
  }
   
  const objectKeys = Object.keys(apiKeys);

  const apiKeysModalContent = () => {
    return (
      <div className={styles.keys_text}>
         <h3>Keep these keys in a safe place</h3>
        <b>{`${objectKeys[0]}:`}</b> <p>{`${apiKeys.APIKey}`}</p>
        <b>{`${objectKeys[1]}:`}</b> <p>{`${apiKeys.secretKey}`}</p>
        <Button primary action={() => navigate(window.location.href.replace(window.location.origin,'').replace(`scene=apiKeys`,''))}>
          Close
        </Button>
      </div>
    )
  }
    
  return (
    <div style={{fontSize: 14, background: '#fff', borderRadius: '10px', margin: '6.2vw 2.2vw', padding: '0 2vw'}}>
      <div className="px-4 py-5 my-5 d-flex flex-column gap-5"
           style={{borderRadius: '10px'}}>
            <Modal 
              isOpen={query.get('scene') === 'apiKeys'} 
              content={apiKeysModalContent()}
          />
        <div className="d-flex ">
          <img onClick = {() => navigate(-1)} src={back} style={{rotate: "180deg", margin: "0 0 20px", cursor: "pointer"}}/>
        </div>
        <div className="row gx-0 d-flex flex-column flex-lg-row gap-4 ">
          <div className={`${styles.input_container}`}>
            <Input sm 
                  width="100%" 
                  label="Api name" 
                  border='1px solid #002D64'
                  placeholder='title'
                  action={(e) => setApiTitle(e.target.value)}/>
          </div>
          <div>
              <label>Available API Addresses</label>
              <div className={`${styles.addresses} form-control d-flex flex-wrap gap-2 align-items-start`}
                    style={{minHeight: '100px'}}>
                  {apiAddresses.map((element, index) => 
                  <Input 
                    value={apiAddresses[index]} 
                    apiAddress 
                    placeholder='IP'
                    sm 
                    width={isMobile ? "100px" : "150px"}
                    action={(e) => handleAddressChange(index, e.target.value) } 
                    closeAction={() => deleteApi(index)}
                  /> 
                  )}
                  <img src={Plus} onClick={handleAddAddress} style={{margin: '0 10px'}}/>
              </div>
          </div>
        </div>
        <div className="d-flex align-items-end justify-content-end justify-content-md-start">
          <Button action={() => submitForm()} transparent disabled={!validApi || !apiTitle || !apiAddresses.length}>
              Create
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CreateApi