// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../static/Ellipse 32.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.update_form_container__JbjFZ {
  padding: 0;
  margin: 0;
}

.update_custom_switch_checkbox__u8etx {
  background-color: #9F9DA5;
  border: unset;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;
  background-size: 16px;
  height: 1.125rem;
  width: 2.25em !important;
  margin-left: 0.5em !important;
  float: unset !important;
}
.update_custom_switch_checkbox__u8etx:checked {
  background-color: #002D64;
}

.update_custom_switch_label__QvTMk {
  font-weight: 600;
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/pages/api/updateApi/update.module.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,SAAA;AACJ;;AACA;EACI,yBAAA;EACA,aAAA;EACA,oEAAA;EACA,qBAAA;EACA,gBAAA;EACA,wBAAA;EACA,6BAAA;EACA,uBAAA;AAEJ;AAAI;EACI,yBAAA;AAER;;AAEA;EACI,gBAAA;EACA,WAAA;AACJ","sourcesContent":[".form_container {\n    padding: 0;\n    margin: 0;\n}\n.custom_switch_checkbox{\n    background-color: #9F9DA5;\n    border: unset;\n    background-image: url('../../../static/Ellipse\\ 32.svg') !important;\n    background-size: 16px;\n    height: 1.125rem;\n    width: 2.25em !important;\n    margin-left: 0.5em !important;\n    float: unset !important;\n\n    &:checked {\n        background-color: #002D64;\n    }\n}\n\n.custom_switch_label {\n    font-weight: 600;\n    color: #000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_container": `update_form_container__JbjFZ`,
	"custom_switch_checkbox": `update_custom_switch_checkbox__u8etx`,
	"custom_switch_label": `update_custom_switch_label__QvTMk`
};
export default ___CSS_LOADER_EXPORT___;
