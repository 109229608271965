import { Navigate, RouteObject } from "react-router-dom"
import Transactions from "../pages/transactoins"
import Settings from "../pages/settings"
import Api from "../pages/api"
import CreateApi from "../pages/api/createApi"
import UpdateApi from "../pages/api/updateApi"
import TwoFactorAuth from "../pages/settings/twoFactor"

export const appRoutes = [
  {
    path: 'transactions',
    element: <Transactions />,
  },
  {
    path: 'settings',
    element: <Settings />,
  },
  {
    path: 'api',
    element: <Api />,
  },
  {
    path: 'create',
    element: <CreateApi />,
  },
  {
    path: 'update',
    element: <UpdateApi />,
  },
  {
    path: 'twoFactor',
    element: <TwoFactorAuth />,
  },
  {
    path: '/*',
    element: <Navigate to={'transactions'} replace />,
  },
]
