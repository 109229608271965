// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create_keys_text__ncDuA {
  word-wrap: break-word;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
}

.create_input_container__sup5X, .create_addresses__9n50o {
  width: 250px;
}

@media (max-width: 768px) {
  .create_input_container__sup5X, .create_addresses__9n50o {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/api/createApi/create.module.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI;IACI,WAAA;EACN;AACF","sourcesContent":[".keys_text {\n    word-wrap: break-word;\n    background-color: #fff;\n    border-radius: 10px;\n    padding: 15px;\n}\n\n.input_container, .addresses {\n    width: 250px;\n}\n\n@media (max-width: 768px) {\n    .input_container, .addresses {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"keys_text": `create_keys_text__ncDuA`,
	"input_container": `create_input_container__sup5X`,
	"addresses": `create_addresses__9n50o`
};
export default ___CSS_LOADER_EXPORT___;
