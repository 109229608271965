import React from 'react';
import useWidth from '../../lib/hooks/useWidth';
import {useDispatch, useSelector} from "react-redux";
// import { useNavigate } from "react-router-dom";
import { logOutUser } from '../../redux/userSlice'
import styles from './sideBarSettings.module.scss'
import logoutIcon from '../../static/logout.svg'
import twoFactor from '../../static/two-factor-authentication.svg'

interface SidebarSettingsProps {
    isSidebarOpen: boolean,
}

const SideBarSettings: React.FC<SidebarSettingsProps> = ({isSidebarOpen}) => {
    const dispatch = useDispatch();
    const [_, mobile] = useWidth(992);
    
    const logout = () => {
        dispatch(logOutUser())
    }

    return (
            <div className={`w-100 d-flex flex-column gap-2`}
                 style={{ whiteSpace: 'nowrap', background: '#fff'}}>
                    {/* <div
                         className={`${styles.auth} d-flex align-items-center justify-content-between`}
                         onClick={() => navigate(`/${window.location.pathname.split('/')[1]}/twoFactor`)}
                    >
                        <span>Two factor auth.</span>
                        <img src={twoFactor} /> */}
                    {/* </div> */}
                    <div
                         className={`${styles.logout} d-flex align-items-center justify-content-between`}
                         onClick={() => logout()}>
                        {isSidebarOpen && <span>Log Out</span> }
                        <img src={logoutIcon} 
                            style={{margin: '0.5vw', cursor: 'pointer'}}
                            />
                    </div>
            </div>
    )
    
}

export default SideBarSettings;