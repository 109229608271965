import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import { urls, fetchapp} from './helpers/urls';
import {addHint} from './hintSlice';

export const getUserBalance = createAsyncThunk(
    'users/getUserBalance',
    async (_) => {
        try {
            const resp = await fetchapp(urls.userGetBalance(), {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": sessionStorage.getItem('authorization') || ''
                },
                credentials: "include",
            })
            
            if (!resp.ok) {
                console.log("resp.status", resp.status)
                throw Error("failed to get user")
            }
            const data = await resp.json();
            // if (JSON.stringify(data).includes('denied')) dispatch(logOutUser());
            if (data.error) return null;//throw Error(data.error);
            return data;

        } catch (e){
            // dispatch(addHint({type: 'danger', text: e.message}))
            console.log("getUserError", e)
            window.sessionStorage.removeItem('authorization')
            return null
        }
});

export const loginUser = createAsyncThunk(
    'users/loginUser',
    async (userData,{dispatch}) => {
        try {
            const resp = await fetchapp(urls.login(), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({...userData})
            })
            if (!resp.ok) throw Error("login failed")
            const data = await resp.json();
            // if (JSON.stringify(data).includes('denied')) dispatch(logOutUser());
            if (data.error) throw data.error;
            sessionStorage.removeItem("authorization");
            sessionStorage.setItem("authorization", resp.headers.get('Authorization'));
            return data

        } catch (e){
            dispatch(addHint({type: 'danger', text: e.message}))
            return null
        }
    });

export const logOutUser = createAsyncThunk(
    'users/logOutUser',
    async (_,{dispatch}) => {
        try {
            await fetchapp(urls.userLogout(), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": sessionStorage.getItem('authorization') || ''
                },
                credentials: "include",
            })
            window.sessionStorage.removeItem('authorization');
            return;

        } catch (e){
            dispatch(addHint({type: 'danger', text: e.message}))
            return null
        }
    });

export const changePassword = createAsyncThunk(
    'users/changePassword',
    async (passData,{dispatch, getState}) => {
        try {
            const resp = await fetchapp(urls.userPasswordChange(), {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": sessionStorage.getItem('authorization')
                },
                credentials: "include",
                body: JSON.stringify(passData)
            })
            if (!resp.ok) throw Error("failed to change password")
            const data = await resp.json();
            // if (JSON.stringify(data).includes('denied')) dispatch(logOutUser());
            if (data.error) throw data.error;
            dispatch(addHint({type: 'success', text: "Your password changed"}))
            return data;

        } catch (e){
            dispatch(addHint({type: 'danger', text: e.message}))
            window.sessionStorage.removeItem('authorization')
            return null
        }
    });
export const setTab = createAsyncThunk(
    'users/setTab',
    async (tab,{dispatch, getState}) => tab);
    
export const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: {},
        data: {},
        tab: 'transactions',
        isPasswordChanged: null,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserBalance.fulfilled, (state, action) => {
                if(action.payload){
                    state.data = {...action.payload};
                }
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                if(action.payload){
                    state.user = {...action.payload};
                    console.log("state.data", state.data)
                }
            })
            .addCase(logOutUser.fulfilled, (state, action) => {
                state.data = null;
            })

            .addCase(changePassword.fulfilled, (state, action) => {
                state.isPasswordChanged = {...action.payload};
            })
            
            .addCase(setTab.fulfilled, (state, action) => {
                 state.tab = action.payload;
            })
    },
})

export default userSlice;
