import Router from "../lib/Router";
import { useMainRoutes } from "../lib/route.ts"
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from './styles/main.module.scss'
import Auth from "../lib/authMiddleware";
import useWidth from "../lib/hooks/useWidth";
import Login from "./login"
import Main from "./main"
import Header from "../pages/header";


export default function Index(){
    const routes = useMainRoutes()
    const lang = window.location.pathname.split('/')[1];
    const [_, isMobile] = useWidth(768)

    const [isSidebarOpen, setSidebarOpen] = useState(isMobile ? false : true);

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
      };
    
    useEffect(()=>{
        if(lang) window.localStorage.setItem('lang',lang)
    },[lang]);

    const navigate = useNavigate();

    useEffect(() => {
        const checkAuthorization = () => {
        if (!sessionStorage.getItem('authorization')) {
            navigate('/login');
        }
        };

        checkAuthorization();

        const handleStorageChange = (event) => {
            if (event.key === 'authorization' && !event.newValue) {
                navigate('/login');
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [navigate]);
    
    return (<>
        {sessionStorage.getItem('authorization') ?
        <Auth content={
        <div style={{minWidth: '100vw', minHeight: '100vh', paddingTop: '96px'}}>
            <Header/>
            <div className="d-flex">
                <Main isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                    <div id={styles.pages} className={`mt-3 ${isSidebarOpen ? styles.openedSideBar : styles.closedSideBar}`}>
                        {routes}
                    </div>
            </div>
        </div>
        
         }/>
         :
         <Login />}
         
    </>)
}
