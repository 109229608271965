import React, {useState} from 'react';
import useWidth from "../../hooks/useWidth";

interface Props {
    warning?: boolean,
    primary?: boolean,
    link?: boolean,
    disabled?: boolean,
    width?: string,
    sm?: boolean,
    children?: React.ReactNode,
    action: React.MouseEventHandler<HTMLButtonElement> | undefined,
    tab?: boolean,
    activeTab?: string,
    tabTarget?: string,
    tabSelected?: boolean,
    tabId?: string,
    menu?: boolean,
    height?: string,
    transparent?: boolean,
}

const Button: React.FC<Props> = ({children, sm, warning, primary, link, disabled, width, action, tab, activeTab, tabTarget, tabSelected, tabId, height, transparent}) => {
    const [_, isMobile] = useWidth();
    const [hover, setHover] = useState(false);

    const [red, grey, yellow, black, white, liteGrey, none] = [
        '#E2400C', '#a59c9c', '#E88D04', '#000000', '#fff', '#B7B6BC', 'none'
    ]
    const getHeight = () => height ? height : isMobile ? (sm ? '22px' : '34px') : tab ? '44px' : (sm ? '32px' : '44px');
    const getBG = () => disabled ? grey : primary ? '#002D64' : warning ? '#B02A37' : ''
    const getTextColor = () => link ? (disabled ? '#4c515f' : primary ? (hover && !(tab || activeTab === 'active') ? red : yellow) : (hover && !(tab || activeTab === 'active') ? yellow : white)) :
        (disabled ? grey : !warning && !primary && hover && !(tab || activeTab === 'active') ? black : transparent ? '#002D64' : white);
    const getTextSize = () => isMobile ? (sm ? '11px' : '12px') : tab ? '16px' : (sm ? '12px' : '14px');
    const getBorder = () => !primary && !warning && !link ? (disabled ? '1px solid ' + grey : hover ? '1px solid #575562' : tab ? '1px solid #F7F7F7' : 'none') : "none"

    const buttonStyles: React.CSSProperties = {
        height: getHeight(),
        padding: tab ? '12px' : '10px 12px',
        background: getBG(),
        color: disabled ? '#4c515f' : getTextColor(),
        borderRadius: tab ? 15 : 5,
        fontSize: getTextSize(),
        border: getBorder(),
        lineHeight: '100%',
        fontWeight: 600,
        textDecoration: none,
        textAlign: 'center',
    };

    const widthStyle: React.CSSProperties = !width ? {} : {width}
    const customStyle = `
    .nav-link {
      color: ${liteGrey} !important;
      white-space: nowrap !important;
    }
    .active {
      background: #8285a7 !important;
      color: white !important
    }
    .show {
      background: unset !important;
      color: unset !important
    }
  `;

    return (
        <>
            <style>{customStyle}</style>
            <button className={tab ? 'nav-link ' + activeTab : 'btn btn-primary'}
                    id={tab ? tabId : ''}
                    data-bs-toggle={tab && "tab"}
                    data-bs-target={tabTarget}
                    role={tab ? 'tab' : ''}
                    aria-controls={tab && 'profile'}
                    aria-selected={tab && tabSelected}
                    style={{...buttonStyles, ...widthStyle}}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    onClick={(!disabled ? action : '') || undefined}
            >
                {children}
            </button>
        </>
    );
};

export default Button;
