// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#sideBar_sidebar__CWHYh {
  transition: 0.3s;
  text-align: center;
  background-color: #fff !important;
  border: none !important;
  color: #000 !important;
}

.sideBar_sidebar_open__C0xnR {
  width: 100%;
}

#sideBar_page-content-wrapper__8MVkj {
  flex-grow: 1;
}

.sideBar_sidebar_heading__\\+bGrI {
  padding: 0.5rem 1rem;
}

.sideBar_menu__3oAEF {
  cursor: pointer;
  padding: 20px 5px;
  border-radius: 8px;
  text-align: center;
}

.sideBar_menu__3oAEF:hover {
  background-color: rgba(161, 158, 158, 0.1803921569);
}

.sideBar_list_group__NWvFB {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 15px;
}

.sideBar_list_group_item__mQhzP {
  cursor: pointer;
  transition: background-color 0.3s;
  padding: 20px 5px;
  border-radius: 8px;
}

.sideBar_list_group_item__mQhzP:hover {
  background-color: rgba(161, 158, 158, 0.1803921569);
}`, "",{"version":3,"sources":["webpack://./src/lib/customBlocks/sideBar/sideBar.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,kBAAA;EACA,iCAAA;EACA,uBAAA;EACA,sBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AACA;EACE,YAAA;AAEF;;AACA;EACE,oBAAA;AAEF;;AACA;EACE,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;AAEF;;AACA;EACE,mDAAA;AAEF;;AACA;EACE,SAAA;EACA,UAAA;EACA,gBAAA;EACA,eAAA;AAEF;;AACA;EACE,eAAA;EACA,iCAAA;EACA,iBAAA;EACA,kBAAA;AAEF;;AACA;EACE,mDAAA;AAEF","sourcesContent":["#sidebar {\n  transition: 0.3s;\n  text-align: center;\n  background-color: #fff !important;\n  border: none !important;\n  color: #000 !important;\n}\n\n.sidebar_open {\n  width: 100%;\n}\n#page-content-wrapper {\n  flex-grow: 1;\n}\n\n.sidebar_heading {\n  padding: 0.5rem 1rem;\n}\n\n.menu {\n  cursor: pointer;\n  padding: 20px 5px;\n  border-radius: 8px;\n  text-align: center;\n}\n\n.menu:hover {\n  background-color: #a19e9e2e;\n}\n\n.list_group {\n  margin: 0;\n  padding: 0;\n  list-style: none;\n  font-size: 15px;\n}\n\n.list_group_item {\n  cursor: pointer;\n  transition: background-color 0.3s;\n  padding: 20px 5px;\n  border-radius: 8px;\n}\n\n.list_group_item:hover {\n  background-color: #a19e9e2e;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `sideBar_sidebar__CWHYh`,
	"sidebar_open": `sideBar_sidebar_open__C0xnR`,
	"page-content-wrapper": `sideBar_page-content-wrapper__8MVkj`,
	"sidebar_heading": `sideBar_sidebar_heading__+bGrI`,
	"menu": `sideBar_menu__3oAEF`,
	"list_group": `sideBar_list_group__NWvFB`,
	"list_group_item": `sideBar_list_group_item__mQhzP`
};
export default ___CSS_LOADER_EXPORT___;
