import {configureStore} from '@reduxjs/toolkit';
import {combineReducers} from 'redux';
import userSlice from './userSlice';
import hintSlice from './hintSlice';
import localeSlice from './localeSlice';
import apiSlice from './apiSlice';
import transactionsSlice from './transactionsSlice'

const rootReducer = combineReducers({
    user: userSlice.reducer,
    local: localeSlice.reducer,
    hint: hintSlice.reducer,
    api: apiSlice.reducer,
    transactions: transactionsSlice.reducer,
})



export default configureStore({
    reducer: rootReducer,
})
