import React from 'react';
import './App.css';
import Index from './app/index.jsx'
import Hints from './lib/Hints';

function App() {

  return (<React.StrictMode>
    <div id='scrollArea' style={{
      background: '#F7F7F7',
      // overflow: 'hidden',
      width: '100vw',
      height: '100vh',
      position: 'relative',
      fontFamily: 'Montserrat',
      boxSizing: 'border-box',
      color: '#002D64'
    }}>
      <Index />
      <Hints />
    </div>
  </React.StrictMode>
  );
}

export default App;
