
const regExPassword = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)(?!.*\s).{8,}$/);
const isPasswordValid = (value) => regExPassword.test(value);

export const Validate = (value, field, error, currentValues) => {
  if (!value) {
    error.error = true;
    error.message = `${field.toUpperCase()} is Required`;
  } else {
    switch (field) {
      // case 'old_passwd':
      // case 'new_passwd':
      //   if (!value || !isPasswordValid(value)) {
      //     error.error = true;
      //     error.message = 'Invalid Password';
      //   } else {
      //     error.error = false;
      //     error.message = '';
      //   }
      //   break;
      case 'repeat':
        if (!value || currentValues.new_passwd !== value) {
          error.error = true;
          error.message = 'Passwords does not match';
        } else {
            
          error.error = false;
          error.message = '';
        }
        break;
      default:
        if (!value) {
          error.error = true;
          error.message = `${field.toUpperCase()} is Required`;
        } else {
          error.error = false;
          error.message = '';
        }
    }
  }
};
