// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.api_api_mobile_list__SeiNY {
  font-size: 17px;
  line-height: 33px;
  font-weight: 500;
}
.api_api_mobile_list__SeiNY p {
  font-weight: 600;
  margin: 5px 0;
}
.api_api_mobile_list__SeiNY span {
  font-size: 14px;
  margin: 5px 0;
}
.api_api_mobile_list__SeiNY img {
  width: 45px;
}
@media (max-width: 430px) {
  .api_api_mobile_list__SeiNY p {
    font-size: 14px;
    font-weight: 600;
  }
  .api_api_mobile_list__SeiNY span {
    font-size: 12px;
  }
  .api_api_mobile_list__SeiNY img {
    width: 40px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/api/api.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,iBAAA;EACA,gBAAA;AACJ;AACE;EACE,gBAAA;EACA,aAAA;AACJ;AAEE;EACE,eAAA;EACA,aAAA;AAAJ;AAGE;EACE,WAAA;AADJ;AAIE;EACE;IACE,eAAA;IACA,gBAAA;EAFJ;EAKE;IACE,eAAA;EAHJ;EAKE;IACE,WAAA;EAHJ;AACF","sourcesContent":[".api_mobile_list {\n    font-size: 17px;\n    line-height: 33px;\n    font-weight: 500;\n    \n  p {\n    font-weight: 600;\n    margin: 5px 0;\n  }\n\n  span {\n    font-size: 14px;\n    margin: 5px 0;\n  }\n\n  img {\n    width: 45px;\n  }\n\n  @media (max-width: 430px) { \n    p {\n      font-size: 14px;\n      font-weight: 600;\n    }\n  \n    span {\n      font-size: 12px;\n    }\n    img {\n      width: 40px;\n    }\n  }\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"api_mobile_list": `api_api_mobile_list__SeiNY`
};
export default ___CSS_LOADER_EXPORT___;
