import React from "react";
import { useNavigate } from "react-router-dom";

import styles from './sideBar.module.scss'

import menu from '../../../static/sidebarMenu.svg'

interface SidebarProps {
    isSidebarOpen: boolean,
    sidebarItems: [{icon: string, name: string}]
    toggleSidebar: () => void
}

const Sidebar: React.FC<SidebarProps> = ({ isSidebarOpen, sidebarItems, toggleSidebar }) => {

    const navigate = useNavigate();

    return (
        <div id={styles.sidebar} className={`bg-light border-end ${isSidebarOpen ? styles.sidebar_open : styles.sidebar_closed}`}>
            <div onClick={toggleSidebar} className={`${styles.menu} d-flex ${!isSidebarOpen && 'justify-content-center'}`}>
               {isSidebarOpen ? 
                <div className="d-flex gap-2">
                    <img src={menu} /> <span style={{verticalAlign: 'bottom'}}>BackOffice</span>
                </div> : 
                <img src={menu} />}
            </div>
            <hr />
            <ul className={`${styles.list_group} d-flex flex-column`} >
                {sidebarItems.map((item, index) => (
                <li key={index} className={`${styles.list_group_item} py-3 px-2`}>
                    {isSidebarOpen ? 
                        <div className='d-flex gap-2' onClick={() => navigate(`/${window.location.pathname.split('/')[1]}/${item.name.toLowerCase()}`)}>
                            <img src={item.icon} /> 
                            <span>{item.name}</span>
                        </div> 
                        : 
                        <img src={item.icon} onClick={() => navigate(`/${window.location.pathname.split('/')[1]}/${item.name.toLowerCase()}`)}/>
                    }
                </li>
                ))}
            </ul>
        </div>
  );
  };
  
  export default Sidebar;
  