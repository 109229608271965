import { useRoutes } from "react-router-dom"
import { appRoutes } from "./Router"

export const useMainRoutes = () => {
  const localRoutes = appRoutes.map(item => ({
    element: item.element,
    path: `en/${item.path}`
  }));  

  return useRoutes(localRoutes)
}
