import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import { loginUser } from "../redux/userSlice";
import useWidth from "../lib/hooks/useWidth.js"
import Button from "../lib/customBlocks/button/button.tsx"
import Input from "../lib/customBlocks/input/input.tsx"
import {useAddHint} from "../lib/Hints"

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const hint = useAddHint();
    const user = useSelector(s=> s.user)

    const [_, mobile] = useWidth();
    const local = useSelector(state => state.local).data;
    const [form, setForm] = useState({});

    const submitForm = () => {
        try {
            dispatch(loginUser({...form}));
        } catch {
            hint('Failed to log in');
        }
    }

    useEffect(() => {
        const  handleKeyDown = (event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            submitForm()
          }
        }
        document.addEventListener('keydown', handleKeyDown);
    
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, [form]);

    useEffect(()=> {
        if(user?.user && window.location.href.includes('login')) {
            navigate('en/transactions')
        }
            
    }, [user.user, navigate])

    return <div
                className='d-flex justify-content-center align-items-center'
                style={{
                    width: '100vw',
                    height: '100vh',
                    color: '#000',
                    padding: '32px',
                    background: '#F7F7F7'
                }}
            >
        <div className='p-4 d-flex flex-column align-items-center gap-4' 
             style={{width: '380px', background: '#fff', borderRadius: 25, boxShadow: '54px 13px 83px rgb(49 44 44 / 10%), 11px 12px 21px rgba(0, 0, 0, 0.08)'}}>
            <div className={mobile ? 'd-flex flex-column w-100' : 'd-flex flex-column w-100'} style={{gap: '8px'}}>
                <div className='d-flex flex-column gap-3'>
                    <Input smallLabel lg
                            label={local ? local['common.login'] : 'Login'}
                            placeholder={local ? local['common.login'] : 'Enter your login'}
                            value={form.login || ''}
                            action={(e)=> {setForm(p=> ({...p, login:e.target.value}))}}
                    />
                    
                    <Input smallLabel lg password
                            label={local ? local['common.password'] : 'Password'}
                            placeholder={local ? local['common.password'] : 'Enter your password'}
                            value={form.passwd || ''}
                            action={(e)=> {setForm(p=> ({...p, passwd:e.target.value}))}}
                    />
                    
                    <Input smallLabel lg
                        label={local ? local['common.password'] : 'Оne-time password'}
                        placeholder={local ? local['common.password'] : 'Enter your OTP'}
                        value={form.oneTimePass || ''}
                        action={(e)=> {setForm(p=> ({...p, oneTimePass:e.target.value}))}}
                    />
                        {/* <label htmlFor="custom_input"
                                style={{display: 'block', color: '#A330FD', fontSize: '12px', float: 'right', cursor: 'pointer'}}
                                onClick={()=> navigate({ search: '?scene=password-recovery' })}
                                >
                                {local ? local['popup.forgot-password'] : 'forgot password?'}
                        </label> */}
                    

                </div>
            </div>
            <Button 
                primary 
                disabled = {!form.login || !form.passwd} 
                width={'100%'}
                action={()=> submitForm()}
              >
                    {local ? local['common.button.log-in'] : 'Log In'}
            </Button>
        </div>
    </div>
         
}

export default Login;