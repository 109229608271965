// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/google-play-badge250.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../public/appstore-badge250.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings_auths__Zjv7C {
  background-color: #fff;
  border-radius: 20px;
  padding: 25px;
  width: 300px;
}
.settings_auths__Zjv7C .settings_auth_text__V1CD1 {
  font-size: 20px;
  font-weight: 600;
}
.settings_auths__Zjv7C .settings_google_auth__XGKdh {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  width: 300px;
  height: 100px;
}
.settings_auths__Zjv7C .settings_app_store__KQIwO {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  background-repeat: no-repeat;
  width: 300px;
  height: 100px;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/settings.module.scss"],"names":[],"mappings":"AACA;EACI,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;AAAJ;AAEI;EACI,eAAA;EACA,gBAAA;AAAR;AAEI;EACI,yDAAA;EACA,4BAAA;EACA,YAAA;EACA,aAAA;AAAR;AAGI;EACI,yDAAA;EACA,4BAAA;EACA,YAAA;EACA,aAAA;AADR","sourcesContent":["\n.auths {\n    background-color: #fff;\n    border-radius: 20px;\n    padding: 25px;\n    width: 300px;\n\n    .auth_text {\n        font-size: 20px;\n        font-weight: 600;\n    }\n    .google_auth {\n        background-image: url('../../../public//google-play-badge250.png');\n        background-repeat: no-repeat;\n        width: 300px;\n        height: 100px;\n    }\n\n    .app_store {\n        background-image: url('../../../public/appstore-badge250.png');\n        background-repeat: no-repeat;\n        width: 300px;\n        height: 100px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"auths": `settings_auths__Zjv7C`,
	"auth_text": `settings_auth_text__V1CD1`,
	"google_auth": `settings_google_auth__XGKdh`,
	"app_store": `settings_app_store__KQIwO`
};
export default ___CSS_LOADER_EXPORT___;
