import {createSlice} from '@reduxjs/toolkit'


export const hintSlice = createSlice({
    name: 'hint',
    initialState: {
        data: [],
    },
    reducers: {
        addHint: (state, action) => {
            if(typeof action.payload === 'string') 
                state.data = [...state.data, {text: action.payload, type: action.payload.msg ? 'warning' : 'success'}];
            else state.data = [...state.data, {...{text: action.payload.text, type: action.payload.type}, ...action.payload}];
        },
        closeLastHint: (state) => {
            state.data = state.data.slice(1);
        },
        closeAll: (state) => {
            state.data = [];
        },
        closeIndex: (state, action) => {
            const res = state.data;
            res.splice(action.payload,1)
            state.data = [...res];
        }

    },

})

export const {addHint, closeLastHint, closeAll, closeIndex} = hintSlice.actions;
export default hintSlice;
