import React, { useRef, useState } from 'react';
import useWidth from "../../hooks/useWidth";
import previous from '../../../static/paginationPreviousArrow.svg'
import next from '../../../static/paginationNextArrow.svg'
import { useEffect } from 'react';


interface Props {
  scroll?: boolean,
  action: void | any,
  pages: number,
  page: number,
}

const Pagination: React.FC<Props> = ({scroll, pages, page, action}) => {
  const [_, mobile] = useWidth();
  const myRef = useRef<HTMLDivElement>(null);
  const showPages = (new Array(pages >= 5 ? 5 : pages)).fill('')?.map((el, i) => 
     (page < 3 ? 0 : page > pages-3 ? pages-(pages >= 5 ? 5 : pages) : (page-3)>0 ? page-3 : 0)+i+1)
  
  const [x, setX] = useState<number | undefined>(undefined);
  const [wasPaginate, SetWasPaginate] = useState<Boolean>(false);

  useEffect(()=>{
    const documentH: number = document.documentElement.scrollHeight;    
    if(Number(x) > documentH+60 && wasPaginate) SetWasPaginate(false);
    if(Number(x) < documentH-30 && !wasPaginate) {
      SetWasPaginate(true);
      action(page > 0? page+1: 1);
    }
  },[x, page, wasPaginate, SetWasPaginate]);

  useEffect(()=>{
    if(scroll){
      const area: HTMLElement | null= document.getElementById('scrollArea');
      
      const apdateX = () => {
        setX(Number(myRef?.current?.getBoundingClientRect()?.top));        
      }
      area?.addEventListener('scroll',apdateX);
      return () => {
        area?.removeEventListener('scroll',apdateX);
      }
    }
  },[scroll]);
  
  return (
    
      scroll? <>
      <div ref={myRef}></div>
      </>
      :
      <>
        <div data-name='pagg'
          className='d-flex justify-content-center text-black text-center'
          style={{
            fontFamily: 'Montserrat',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
          }}
        >
          <div className={mobile? 'me-auto ms-3' : ''}
            style={{
              width: '24px',
              height: '24px',
              margin: '5px',
              cursor: 'pointer',
            }}
            onClick={()=> action(page-1 > 1 ? page-1 : 1)}
          >
            <img style={{
                width: '6px',
                height: '12px',
                margin: '6px 0'
            }} 
                src={previous} 
                alt="previous" 
            />
          </div>
          {showPages.map(el => <div 
              key={el}
              style={{
                width: '24px',
                height: '24px',
                margin: '6px',
                cursor: 'pointer',
                ...(el===page? {
                    background: '#a29ba7',
                    borderRadius: '8px'
                  } : {})
              }}
              onClick={()=> action(el)}
            >
              {el}
            </div>
          )}
          <div className={mobile? 'ms-auto me-3' : ''}
            style={{
              width: '24px',
              height: '24px',
              margin: '5px',
              cursor: 'pointer',
            }}
            onClick={()=> action(page+1 < pages ? page+1 : pages)}
          >
            <img style={{
                width: '6px',
                height: '12px',
                margin: '6px 0'
            }} 
                src={next} 
                alt="next" 
            />
          </div>
        </div>
      </> 
    
  );

};

export default Pagination;