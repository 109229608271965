import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import { urls, fetchapp} from './helpers/urls';
import {addHint} from './hintSlice';

export const getApiList = createAsyncThunk(
    'api/getList',
    async (_,{dispatch}) => {
        try {
            const resp = await fetchapp(urls.listApi(), {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": sessionStorage.getItem('authorization') || ''
                },
                credentials: "include",
            })
            if (!resp.ok) throw Error("failed to get Api list")
            const data = await resp.json();
            if (data.error) return null;
            return data;

        } catch (e){
            dispatch(addHint({type: 'danger', text: e.message}))
            // window.sessionStorage.removeItem('authorization')
            return null
        }
});

export const createApi = createAsyncThunk(
    'api/createApi',
    async (apiData,{dispatch}) => {
        try {
            const resp = await fetchapp(urls.apiCreate(), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": sessionStorage.getItem('authorization')
                },
                credentials: "include",
                body: JSON.stringify({...apiData})
            })
            if (!resp.ok) throw Error("create API failed")
            const data = await resp.json();
            if (data.error) throw data.error;
            dispatch(addHint({type: 'success', text: "Api was successfully created"}))
            return data
        } catch (e){
            dispatch(addHint({type: 'danger', text: e.message}))
            // window.sessionStorage.removeItem('authorization')
            return null
        }
    });

export const getDetailApi = createAsyncThunk(
    'api/getDetailApi',
    async (apiID, {dispatch}) => {
        try {
            const resp = await fetchapp(urls.apiDetail(), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": sessionStorage.getItem('authorization')
                },
                credentials: "include",
                body: JSON.stringify({...apiID})
            })
            if (!resp.ok) throw Error("Getting details of API failed")
            const data = await resp.json();
            if (data.error) throw data.error;
            return data
        } catch (e){
            dispatch(addHint({type: 'danger', text: e.message}))
            // window.sessionStorage.removeItem('authorization')
            return null
        }
    });

export const saveApi = createAsyncThunk(
    'api/saveApi',
    async (apiData,{dispatch}) => {
        try {
            const resp = await fetchapp(urls.apiSave(), {
                method: "Put",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": sessionStorage.getItem('authorization')
                },
                credentials: "include",
                body: JSON.stringify({...apiData})
            })
            if (!resp.ok) throw Error("save API failed")
            const data = await resp.json();
            if (data.error) throw data.error;
            dispatch(addHint({type: 'success', text: "Api saved"}))
            return data
        } catch (e){
            dispatch(addHint({type: 'danger', text: e.message}))
            // window.sessionStorage.removeItem('authorization')
            return null
        }
    });

export const updateApi = createAsyncThunk(
    'api/updateApi',
    async (apiData,{dispatch}) => {
        console.log("apiData", apiData)
        try {
            const resp = await fetchapp(urls.apiUpdate(), {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": sessionStorage.getItem('authorization')
                },
                credentials: "include",
                body: JSON.stringify({...apiData})
            })
            if (!resp.ok) throw Error("create API failed")
            const data = await resp.json();
            if (data.error) throw data.error;
            dispatch(addHint({type: 'success', text: "Api was successfully updated"}))
            return data
        } catch (e){
            dispatch(addHint({type: 'danger', text: e.message}))
            // window.sessionStorage.removeItem('authorization')
            return null
        }
    });

export const deleteGivenApi = createAsyncThunk(
    'api/deleteGivenApi',
    async (id,{dispatch}) => {
        try {
            const resp = await fetchapp(urls.apiDelete(), {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": sessionStorage.getItem('authorization')
                },
                credentials: "include",
                body: JSON.stringify({...id})
            })
            if (!resp.ok) throw Error("delete API failed")
            const data = await resp.json();
            if (data.error) throw data.error;
            dispatch(addHint({type: 'success', text: "Api was successfully deleted"}))
            return data
        } catch (e){
            dispatch(addHint({type: 'danger', text: e.message}))
            // window.sessionStorage.removeItem('authorization')
            return null
        }
    });
export const apiSlice = createSlice({
    name: 'api',
    initialState: {
        data: null,
        givenApi: null,
        isUpdated: false,
        isDeleted: false,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getApiList.fulfilled, (state, action) => {
                if(action.payload.APIList){
                    state.data = [...action.payload.APIList];
                }
            })
            .addCase(createApi.fulfilled, (state, action) => {
                if(action.payload){
                    state.data = {...action.payload};
                }
            })
            .addCase(getDetailApi.fulfilled, (state, action) => {
                if(action.payload){
                    state.givenApi = {...action.payload};
                }
            })
            .addCase(updateApi.fulfilled, (state, action) => {
                if(action.payload){
                    state.isUpdated = {...action.payload}
                }
            })
            .addCase(deleteGivenApi.fulfilled, (state, action) => {
                if(action.payload){
                    state.isDeleted = {...action.payload.success}
                }
            })
    },
})

export default apiSlice;
