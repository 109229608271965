import React, {useState, useEffect } from "react";
import DatePicker from 'react-datepicker';
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';

import { generateReport, getTransactionsList, getStatuses } from '../../redux/transactionsSlice'
import 'react-datepicker/dist/react-datepicker.css';

import useWidth from "../../lib/hooks/useWidth" 

import styles from './transactions.module.scss'
import lock from '../../static/lock.svg'
import calendar from '../../static/calendar.svg'
import time from '../../static/icons8-time.svg'
import Button from "../../lib/customBlocks/button/button.tsx";
import Input from '../../lib/customBlocks/input/input.tsx'
import Pagination from "../../lib/customBlocks/pagination/pagination.tsx";

const Transactions: React.FC<any> = () => { 
    const dispatch = useDispatch();

    const [_, isMobile] = useWidth(576);
    
    const transactions = useSelector((state: any) => state.transactions)?.data?.transactions || [];
    const totalLength = useSelector((state: any) => state.transactions)?.data?.totalLen || null;
    const statuses = useSelector((state: any) => state.transactions)?.statuses || [];

    const [form, setForm] = useState<any>([]);
    const [reportDate, setReportDate] = useState<any>([]);
    const [thCount, setThCount] = useState<number>(0);
    const [isTruncated, setIsTruncated] = useState([]);
    
    useEffect(() => {
            setForm((prev: any) => ({...prev, limit: 20}));
            dispatch(getTransactionsList(payload))
            dispatch(getStatuses())
    }, [])

    useEffect(() => {
        transactions?.length && setIsTruncated(Array(transactions.length)?.fill(true))
    }, [transactions])


    const toggleText = (index) => {
        const updatedIsTruncated = [...isTruncated];
        updatedIsTruncated[index] = !updatedIsTruncated[index];
        setIsTruncated(updatedIsTruncated);
    };

    const payload = {
        amountFrom: null,
        amountTo:null,
        createdAtFrom:null,
        createdAtTo:null,
        updatedAtFrom: null,
        updatedAtTo: null,
        limit: form.limit || 20,
        offset: thCount || 0,
        externalId: form.UUID || null,
        orderType:['deposit','withdraw'],
        statusGroupID:[1,2,3,4]
    }

    const getTransactionList = (offset) => {
        setThCount(offset)
        const payload = {
            amountFrom: Number(form.sumFrom) || null,
            amountTo: Number(form.sumTo) || null,
            createdAtFrom: (form.createdDateFrom && form.createdTimeFrom) ? (moment(form.createdDateFrom).format('YYYY-MM-DDT') +
                           moment(form.createdTimeFrom).utcOffset('+04:00').format('HH:mm:ss[Z]')) : (form.createdDateFrom && !form.createdTimeFrom)
                           ? moment(form.createdDateFrom).format('YYYY-MM-DD') : null,
            createdAtTo: (form.createdDateTo && form.createdTimeTo) ? (moment(form.createdDateTo).format('YYYY-MM-DDT') +
                          moment(form.createdTimeTo).utcOffset('+04:00').format('HH:mm:ss[Z]')) : (form.createdDateTo && !form.createdTimeTo) ? 
                          moment(form.createdDateTo).format('YYYY-MM-DD') : null,

            updatedAtFrom: (form.updatedDateFrom && form.updatedTimeFrom) ? (moment(form.updatedDateFrom).format('YYYY-MM-DDT') +
                            moment(form.updatedTimeFrom).utcOffset('+04:00').format('HH:mm:ss[Z]')) : (form.updatedDateFrom && !form.updatedTimeFrom)
                            ? moment(form.updatedDateFrom).format('YYYY-MM-DD') : null,
            updatedAtTo: (form.updatedDateTo && form.updatedTimeTo) ? (moment(form.updatedDateTo).format('YYYY-MM-DDT') +
                            moment(form.updatedTimeTo).utcOffset('+04:00').format('HH:mm:ss[Z]')) : (form.updatedDateTo && !form.updatedTimeTo) ? 
                            moment(form.updatedDateTo).format('YYYY-MM-DD') : null,

            limit: form.limit || 20,
            offset: offset || 0,
            externalId: form.UUID || null,
            orderType:form.orderType ? form.orderType : ['deposit','withdraw'],
            statusGroupID: form.statusGroupID ? [Number(form.statusGroupID)] : [1,2,3,4]
        }
        dispatch(getTransactionsList(payload))
    }
    const getStatus = (statusId) => statuses.find((status) => status.id === statusId)?.name

    const reportFromDateChange = (date, dateType) => {
          setReportDate((prev: any) => ({...prev, [dateType]: date}));
    }
    const reportFromTimeChange = (time, dateType) => {
        setReportDate((prev: any) => ({...prev, [dateType]: time}));
    }
    const reportToDateChange = (date, dateType) => {
        setReportDate((prev: any) => ({...prev, [dateType]: date}));
    }
    const reportToTimeChange = (time, dateType) => {
        setReportDate((prev: any) => ({...prev, [dateType]: time}));
    }
    const handleFromDateChange = (date, dateType) => {
        
        setForm((prev: any) => ({...prev, [dateType]: date}))
    };

    const handleFromTimeChange = (time, dateType) => {
      setForm((prev: any) => ({...prev, [dateType]: time}))
    };

    const handleToDateChange = (date, dateType) => {
        setForm((prev: any) => ({...prev, [dateType]: date}))
      };
  
    const handleToTimeChange = (time, dateType) => {
        setForm((prev: any) => ({...prev, [dateType]: time}))
    };

    const dataActions = (action) => {
        const payload = {
            amountFrom: Number(form.sumFrom) || null,
            amountTo: Number(form.sumTo) || null,
            createdAtFrom: (form.createdDateFrom && form.createdTimeFrom) ? (moment(form.createdDateFrom).format('YYYY-MM-DDT') +
                           moment(form.createdTimeFrom).utcOffset('+04:00').format('HH:mm:ss[Z]')) : (form.createdDateFrom && !form.createdTimeFrom)
                           ? moment(form.createdDateFrom).format('YYYY-MM-DD') : null,
            createdAtTo: (form.createdDateTo && form.createdTimeTo) ? (moment(form.createdDateTo).format('YYYY-MM-DDT') +
                          moment(form.createdTimeTo).utcOffset('+04:00').format('HH:mm:ss[Z]')) : (form.createdDateTo && !form.createdTimeTo) ? 
                          moment(form.createdDateTo).format('YYYY-MM-DD') : null,
            updatedAtFrom: (form.updatedDateFrom && form.updatedTimeFrom) ? (moment(form.updatedDateFrom).format('YYYY-MM-DDT') +
                            moment(form.updatedTimeFrom).utcOffset('+04:00').format('HH:mm:ss[Z]')) : (form.updatedDateFrom && !form.updatedTimeFrom)
                            ? moment(form.updatedDateFrom).format('YYYY-MM-DD') : null,
            updatedAtTo: (form.updatedDateTo && form.updatedTimeTo) ? (moment(form.updatedDateTo).format('YYYY-MM-DDT') +
                            moment(form.updatedTimeTo).utcOffset('+04:00').format('HH:mm:ss[Z]')) : (form.updatedDateTo && !form.updatedTimeTo) ? 
                            moment(form.updatedDateTo).format('YYYY-MM-DD') : null,
            limit: form.limit || 20,
            offset: thCount || 0,
            externalId: form.UUID || null,
            orderType:form.orderType ? form.orderType : ['deposit','withdraw'],
            statusGroupID: form.statusGroupID ? [Number(form.statusGroupID)] : [1,2,3,4]
            }
           action === 'orderReport' ? dispatch(generateReport(payload)) : dispatch(getTransactionsList(payload))
    }

    const resetData = () => {
        setForm((prev: any) => ({...prev, sumFrom: null,
                                          sumTo: null, 
                                          createdDateFrom: null, 
                                          createdTimeFrom: null, 
                                          createdDateTo: null, 
                                          createdTimeTo: null, 
                                          updatedDateFrom: null,
                                          updatedTimeFrom: null,
                                          updatedDateTo: null,
                                          updatedTimeTo: null,
                                          limit: 20,
                                          offset: 1,
                                          externalId: null,
                                          orderType:  ['deposit','withdraw'],
                                          statusGroupID: [1,2,3,4]
                                           }));
        dispatch(getTransactionsList(payload))
    }

    const CustomDatePickerInput = ({placeholder, value, onClick, className}) => (
        <input
          type="text"
          value={value}
          onClick={onClick}
          className={className}
          placeholder={placeholder}
          readOnly
          style={{
            width: !className.includes('time') || isMobile ? '100%' : '50%',
            padding: '0.375rem 0.75rem',
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: 1.5,
            background: '#fff',
            backgroundClip: 'padding-box',
            border: '1px solid #ced4da',
            appearance: 'none',
            borderRadius: '0.375rem',
            transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
            borderLeft: 0,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0
        }}
          
        />
      );
      const fromTo = (report = null, dateType) => (
       <>
        <div className="col-md-6">
                <h6>{`${dateType.charAt(0).toUpperCase() + dateType.slice(1)} From`}</h6>
                <div className="d-flex flex-column flex-sm-row gap-2">
                    <div className="input-group align-items-center flex-nowrap">
                        <span className={`input-group-text ${styles.text}`}>
                            <img src={calendar} style={{height: '24px'}} />
                        </span>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={report ? reportDate?.[`${dateType}DateFrom`] : form?.[`${dateType}DateFrom`]}
                            onChange={(e) => report ? reportFromDateChange(e, `${dateType}DateFrom`) : handleFromDateChange(e, `${dateType}DateFrom`)}
                            customInput={<CustomDatePickerInput 
                                            placeholder="dd/mm/yyyy" 
                                            className = 'date' 
                                            value={report ? reportDate?.[`${dateType}DateFrom`] : form?.[`${dateType}DateFrom`]} 
                                            onClick={(e) => report ? reportFromDateChange(e, `${dateType}DateFrom`) : handleFromDateChange(e, `${dateType}DateFrom`)}
                                        />}
                        />
                    </div>

                    <div className="input-group align-items-center flex-nowrap">
                        <span className={`input-group-text ${styles.text}`} >
                            <img src={time} style={{height: '24px'}}/>
                        </span>
                        <DatePicker
                            selected={report ? reportDate?.[`${dateType}TimeFrom`] : form?.[`${dateType}TimeFrom`]}
                            onChange={(e) => report ? reportFromTimeChange(e, `${dateType}TimeFrom`) : handleFromTimeChange(e,`${dateType}TimeFrom` )}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            className="form-control"
                            customInput={<CustomDatePickerInput 
                                            className = 'time' 
                                            placeholder="--:--" 
                                            value={report ? reportDate?.[`${dateType}TimeFrom`] : form?.[`${dateType}TimeFrom`]} 
                                            onClick={(e) => report ? reportFromTimeChange(e, `${dateType}TimeFrom`) : handleFromTimeChange(e, `${dateType}TimeFrom`)}
                                        />}
                        />
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <h6>{`${dateType.charAt(0).toUpperCase() + dateType.slice(1)} To`}</h6>
                <div className="d-flex flex-column flex-sm-row gap-2">
                    <div className="input-group align-items-center flex-nowrap">
                        <span className={`input-group-text ${styles.text}`}>
                        <img src={calendar} style={{height: '24px'}}/>
                        </span>
                        <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={report ? reportDate?.[`${dateType}DateTo`] : form?.[`${dateType}DateTo`]}
                        onChange={(e) => report ? reportToDateChange(e, `${dateType}DateTo`) : handleToDateChange(e, `${dateType}DateTo`)}
                        customInput={<CustomDatePickerInput 
                                        placeholder="dd/mm/yyyy" 
                                        className = 'date' 
                                        value={report ? reportDate?.[`${dateType}DateTo`] : form?.[`${dateType}DateTo`]} 
                                        onClick={(e) => report ? reportToDateChange(e, `${dateType}DateTo`) : handleToDateChange(e, `${dateType}DateTo`)}
                                    />}
                        />
                    </div>

                    <div className="input-group align-items-center flex-nowrap">
                        <span className={`input-group-text ${styles.text}`} >
                            <img src={time} style={{height: '24px'}}/>
                        </span>
                        <DatePicker
                        selected={report ? reportDate?.[`${dateType}TimeTo`] : form?.[`${dateType}TimeTo`]}
                        onChange={(e) => report ? reportToTimeChange(e, `${dateType}TimeTo`) : handleToTimeChange(e, `${dateType}TimeTo`)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="HH:mm:ss"
                        className="form-control"
                        customInput={<CustomDatePickerInput 
                                        className = 'time' 
                                        placeholder="--:--" 
                                        value={report ? reportDate?.[`${dateType}TimeTo`] : form?.[`${dateType}TimeTo`]} 
                                        onClick={(e) => report ? reportToTimeChange(e, `${dateType}TimeTo`) : handleToTimeChange(e, `${dateType}TimeTo`)}
                                    />}
                        />
                    </div>
                </div>
            </div>
        </>
    )

    return <div style={{fontSize: 14, height: '100vh'}}>
        <div className="px-3 py-4 row"
             style={{background: '#fff', borderRadius: '10px'}}>
            <div className="pe-5 d-flex flex-column justify-content-around align-items-end">
                <label htmlFor="limit">Limit</label>
                <select
                    id="limit"
                    value={form.limit }
                    className="form-select" 
                    style={{width: '120px'}} 
                    aria-label="Default select example"
                    onChange={(e) => setForm((prev: any) => ({...prev, limit: Number(e.target.value)}))}
                    >
                    <option selected>20</option>
                    <option value={40}>40</option>
                    <option value={60}>60</option>
                    <option value={80}>80</option>
                </select>
            </div>
            
            <div className="pe-0 row gy-3">
                <div className="pe-0 d-flex row gy-3" style={{width: '100%'}}>
                    {fromTo(null, 'created')}
                </div>
                <div className="pe-0 d-flex justify-content-between row gy-3">
                    {fromTo(null, 'updated')}
                </div>
            </div>

            <div className="my-4 d-flex justify-content-between flex-column flex-sm-row flex-wrap" style={{rowGap: 30}}>
                <div className="w-100 d-flex flex-column justify-content-between gap-5">
                    <div className="d-flex flex-wrap gap-4">
                        <Input label="" placeholder="Sum from" border="1px solid #ced4da" 
                            value={form.sumFrom || ''}
                            action={(e) => setForm((prev: any) => ({...prev, sumFrom: e.target.value}))} />
                        <Input label="" placeholder="Sum to" border="1px solid #ced4da" 
                            value={form.sumTo || ''}
                            action = {(e) => setForm((prev: any) => ({...prev, sumTo: e.target.value}))}/>
                        <Input label="" placeholder="UUID" border="1px solid #ced4da"
                            value={form.externalId || null}
                            action = {(e) => setForm((prev: any) => ({...prev, UUID: e.target.value}))} />
                    </div>
                    <div className="w-100 d-flex gap-4 ">
                        <div className="d-flex flex-column gap-1">
                                <label>Order Type</label>
                                <select 
                                    value={form.orderType}
                                    className="form-select" 
                                    aria-label="Default select example"
                                    onChange={(e) => setForm((prev: any) => ({...prev, orderType: [e.target.value]}))}
                                    >
                                    <option selected></option>
                                    <option value="deposit">Deposit</option>
                                    <option value="withdraw">Withdraw</option>
                                </select>
                        </div>
                        <div className="d-flex flex-column gap-1">
                            <label>Order Status</label>
                            <select 
                                value={form.statusGroupID}
                                className="form-select" 
                                aria-label="Default select example"
                                onChange={(e) => setForm((prev: any) => ({...prev, statusGroupID: e.target.value}))}
                                >
                                <option selected></option>
                                <option value="1">{getStatus(1)}</option>
                                <option value="2">{getStatus(2)}</option>
                                <option value="3">{getStatus(3)}</option>
                                <option value="4">{getStatus(4)}</option>
                            </select>
                        </div>
                    </div>
                </div>
               
                <div className="w-100 d-flex align-items-end justify-content-end gap-3">
                    <div className="d-flex align-items-end">
                            <Button primary width = '100%' action={() => dataActions('filter')}>
                                Filter
                            </Button>
                    </div>
                    <div className="d-flex align-items-end">
                            <Button primary width = '100%' action={() => resetData()}>
                                Reset
                            </Button>
                    </div>
                    <div className="d-flex align-items-end">
                            <Button
                                width = '100%'
                                primary
                                action={() => dataActions('orderReport')}>
                                Download report
                            </Button>
                    </div>
                </div>
            </div>
        </div>

        <div className="table-responsive p-5 my-5 row gy-3 "
              style={{background: '#fff', borderRadius: '10px'}}>
            {transactions.length ?
                <>
                    {!isMobile ? 
                        <table className="w-100 table">
                            <thead>
                                <tr>
                                <th scope="col">UUID</th>
                                <th scope="col">Date</th>
                                <th scope="col">Finish Date</th>
                                <th scope="col">Payment Card</th>
                                <th scope="col">Sum</th>
                                <th scope="col">Sum Fact</th>
                                <th scope="col">Currency</th>
                                <th scope="col">Commission</th>
                                <th scope="col">Type</th>
                                <th scope="col">Status</th>
                                <th scope="col">Error</th>
                                <th scope="col">Frozen</th>
                                </tr>
                            </thead>
                            <tbody className="" style={{fontSize: 15}}>
                                {transactions.map((data, index) =>
                                <tr className={index % 2 === 0 ? styles.even: styles.odd}>
                                <td onClick={() => toggleText(index)} id={styles.truncatedText} >{isTruncated[index] ? `${data.external_id.substring(0, 10 - 3)}...` : data.external_id}
                                </td>
                                <td>{data.created_at.replace(/T/, ' ').replace(/\..+/, '')}</td>
                                <td>{data.updated_at.replace(/T/, ' ').replace(/\..+/, '')}</td>
                                <td>{data.card_mask}</td>
                                <td>{data.amount}</td>
                                <td>{data.user_pay_amount}</td>
                                <td>{data.currency}</td>
                                <td>{data.fee}</td>
                                <td>{data.type.charAt(0).toUpperCase() + data.type.slice(1)}</td>
                                <td>{getStatus(data.status)}</td>
                                <td>{data.error.charAt(0).toUpperCase() + data.error.slice(1)}</td>
                                <td>{data.frozen}
                                <img src={lock} /> 
                                </td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                        :
                        transactions.map((data, index) => 
                            <div key={index} className={`${styles.mobile_block} mb-3 d-flex flex-column gap-4`} style={{border: '1px solid #272435', borderRadius: 12, background: '#f8f8f8'}}>
                                <div className='px-2 py-3'>
                                    <ul className='list-unstyled'>
                                        <li className='d-flex align-items-center justify-content-between'>
                                            <p>UUID</p>
                                            <span onClick={() => toggleText(index)} style={{textAlign: 'end'}}>{data.external_id.substring(0, 18 - 3)} </span>
                                        </li>
                                        <li className='d-flex align-items-center justify-content-between'>
                                            <p>Date</p>
                                            <span>{data.created_at.replace(/T/, ' ').replace(/\..+/, '')} </span>
                                        </li>
                                        <li className='d-flex align-items-center justify-content-between'>
                                            <p>Updated</p>
                                            <span>{data.updated_at.replace(/T/, ' ').replace(/\..+/, '')} </span>
                                        </li>
                                        <li className='d-flex align-items-center justify-content-between'>
                                            <p>Payment Card</p>
                                            <span>{data.card_mask}</span>
                                        </li>
                                        <li className={`d-flex align-items-center justify-content-between`}>
                                            <p>Amount</p>
                                            <span>{data.amount}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        )
                    }
                    <Pagination
                        pages={Math.ceil(totalLength/(form.limit || 20))}
                        page={thCount + 1}
                        action={(i)=> getTransactionList(i - 1)}
                    />
                </>
                :
                <div className="d-flex align-items-center justify-content-center">
                    <h2>No transaction found</h2>
                </div>
            }
            </div>
        
        </div>
}

export default Transactions