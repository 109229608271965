import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import useWidth from '../../lib/hooks/useWidth.js';

import styles from './header.module.scss' 

// import logo from "../../static/transparent.svg"

interface currency {
    amount: string,
    frozen: string,
    symbol: string
}
interface HeaderProps {
    title: string,
}

const Header: React.FC<HeaderProps> = () => {
    // const dispatch = useDispatch();
    // const navigate = useNavigate();
    const location = useLocation();
    const [_, mobile] = useWidth(768);
    const [currency, setCurrency] = useState({
        amount: "0.000000",
        frozen: "0.000000",
        symbol: '₽',
    })

    const currencySumbol = {'UAH': '₴', 'RUB': '₽', 'KZT': '₸'}
    const balance = useSelector((state: any) => state.user).data.balances;
    
    useEffect(() => {
        const givenCurrency = Object.keys(balance)[0];
        balance && setCurrency((prepState) => ({...prepState, amount: Object.values<currency>(balance)[0]['amount'], frozen: Object.values<currency>(balance)[0]['frozen'], 
                   symbol: currencySumbol[givenCurrency]
        }))
    }, [balance]
)   
    const title = location.pathname.split('/')[2];
    // const backToMainPage = () => {
    //     navigate('en/transactions')
    //     dispatch(setTab('transactions'))
    // }

    return (
        <div>
            {
            <div className={styles.head}>
                <div className='pt-5 pb-lg-4 pb-2 px-lg-4 px-3 w-100 d-flex justify-content-between'>
                    {/* <div style={{ minWidth: 202}}>
                        <img src={logo} style={{width: mobile ? 40 : 50, cursor: 'pointer'}} onClick={backToMainPage}/>
                    </div> */}
                    <div className='w-100 d-flex flex-column flex-lg-row justify-content-between align-items-lg-center align-items-end'>
                        <span style={{fontSize: mobile ? 20 : '30px'}}>{title?.charAt(0).toUpperCase() + title?.slice(1)}</span>
                        <span style={{color: '#000', fontWeight: 600, fontSize: mobile ? 13 : '18px'}}>
                            Balance {Number(currency['amount']).toFixed(2)}. {currency.symbol} | {Number(currency['frozen']).toFixed(2)} {currency.symbol}
                        </span>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default Header
