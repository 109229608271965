import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import { urls, fetchapp} from './helpers/urls';
import {addHint} from './hintSlice';
// import Papa from 'papaparse';
// import { Readable } from 'stream';

export const generateReport = createAsyncThunk(
    'api/generateReport',
    async (interval,{dispatch}) => {
        
        try {
            console.log("interval", interval)
            const resp = await fetchapp(urls.reportGenerate(), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": sessionStorage.getItem('authorization') || ''
                },
                credentials: "include",
                body: JSON.stringify({...interval})
            })
            
            if (!resp.ok) { throw Error("failed to get Reports"), dispatch(addHint({type: "danger", text: "List error"})) }
            
            const blobData = await resp.blob();
            const blob = new Blob([blobData], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `report.csv`;
            link.click();
            URL.revokeObjectURL(url);

            dispatch(addHint({type: 'success', text: "Download Report"}))

        } catch (e){
            addHint({type: 'danger', text: e.message})
            // window.sessionStorage.removeItem('authorization')
            return null
        }
});

export const getTransactionsList = createAsyncThunk(
    'api/getTransactionsList',
    async (list,{dispatch}) => {
        try {
            const resp = await fetchapp(urls.listTransactions(), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": sessionStorage.getItem('authorization') || ''
                },
                credentials: "include",
                body: JSON.stringify({...list})
            })
            if (!resp.ok) throw Error("failed to get Api list")
            const data = await resp.json();
            if (data.error) return null;
            return data;
        } catch (e){
            dispatch(addHint({type: 'danger', text: e.message}))
            // window.sessionStorage.removeItem('authorization')
            return null
        }
});

export const getStatuses = createAsyncThunk(
    'api/getStatuses',
    async (_,{dispatch}) => {
        try {
            const resp = await fetchapp(urls.listStatuses(), {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": sessionStorage.getItem('authorization') || ''
                },
                credentials: "include",
            })
            if (!resp.ok) throw Error("failed to get Api list")
            const data = await resp.json();
            if (data.error) return null;
            return data;

        } catch (e){
            dispatch(addHint({type: 'danger', text: e.message}))
            window.sessionStorage.removeItem('authorization')
            return null
        }
});

export const transactionsSlice = createSlice({
    name: 'transactions',
    initialState: {
        data: null,
        report: null,
        statuese: null,
    },
    extraReducers: (builder) => {
        builder

            .addCase(getTransactionsList.fulfilled, (state, action) => {
                if(action.payload){
                    state.data = action.payload.transactions?.length ? {...action.payload} : {};
                }
            })
            .addCase(getStatuses.fulfilled, (state, action) => {
                if(action.payload){
                    state.statuses = [...action.payload];
                }
            })
    },
})

export default transactionsSlice;
