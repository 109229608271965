import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addHint, closeAll, closeLastHint, closeIndex } from "../redux/hintSlice";
import useWidth from "../lib/hooks/useWidth";
import warningIco from '../static/danger.svg';
import successIco from '../static/tick-circle.svg';
import infoIco from '../static/info-circle.svg';
import dangerIco from '../static/close.svg';
import closeIco from '../static/close-circle.svg';

export const useAddHint = () => {
    const dispatch = useDispatch();
    /**hint.text - string, hint.type - 'warning' | 'success' | 'info' | 'danger'*/
    return (hint) => dispatch(addHint(hint));
} 

export const useCloseLastHint = () => {
    const dispatch = useDispatch();
    return () => dispatch(closeLastHint());
} 

export const useCloseAll = () => {
    const dispatch = useDispatch();
    return () => dispatch(closeAll());
} 

export default function Hints() {
    const [_,mobile] = useWidth();
    const dispatch = useDispatch();
    const {data: hints} = useSelector(state => state.hint);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const closeLastHandler = () => {
        dispatch(closeLastHint())
    }

    const closeHandler = (i) => {
        dispatch(closeIndex(i))
    }


    useEffect(() => {
        (async () => {
            if(hints.length){
                await new Promise((res)=> setTimeout(()=>res(), 4000));
                closeLastHandler();
            }
        })();
    }, [hints, closeLastHandler]);

    const type = {
        'success':{ icon: successIco, text: 'Success', bg: '#52F21A'},
        'danger': { icon: dangerIco, text: 'Error', bg: '#F21A1A'},
        'info':   { icon: infoIco, text: 'Info', bg: '#1A98F2'},
        'warning':{ icon: warningIco, text: 'Attention', bg: '#F2C31A'},
    
    }
    
    return hints.length && (<>

            <div  
                style={{
                    maxWidth: mobile? '230px':'320px',
                    position: 'fixed',
                    top: '1rem',
                    right: '1rem',
                    fontFamily: 'Montserrat',
                    color: 'white',
                    zIndex: 1000000000000000,
                }}>
                {hints.map((hint,i) => 
                <div key={hint.text} className="mb-3">
                    <div className="d-flex align-items-start"
                        style={{
                            background: '#07050F',
                            width: mobile? '230px':'320px',
                            borderRadius: '12px',
                            position: 'relative'
                        }}
                    >
                        <img src={closeIco} alt="close" 
                            style={{
                                position: 'absolute',
                                top: '12px',
                                right: '12px',
                                cursor: 'pointer'
                            }}
                            onClick={()=> closeHandler(i)}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                top: 0, left: 0,
                                width: '6px',
                                height: '100%',
                                background: type[hint.type].bg,
                                borderRadius: '12px 0 0 12px', 

                            }}
                        />
                        
                        <div className="d-flex align-items-start"
                            style={{
                                margin: '12px',
                            }}
                        >
                            <img src={type[hint.type].icon} alt="ico" 
                                style={{
                                    marginLeft: '10px'
                                    
                                }}
                            />
                            <div className="ms-2">
                                <h2
                                    style={{
                                        fontWeight: 600,
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                    }}
                                >{type[hint.type].text}
                                </h2>
                                <div
                                    style={{
                                        maxWidth: '220px',
                                        overflow: 'auto',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                    }}
                                >
                                    {hint.text}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                )}
            </div>
    </>)
};
