import { useEffect, useState } from "react";

export default function useWidth(screenWidth = 500){
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const resizeScreen = () => {
          setWidth(window.innerWidth);
        };
    
        // window.addEventListener("resize", resizeScreen); // Add event listener for resize
    
        return () => {
          window.removeEventListener("resize", resizeScreen); // Clean up event listener on unmount
        };
      }, []);
    

    return [width, width < screenWidth]
}