import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {fetchapp, urls, setLeng} from './helpers/urls';
import {addHint} from './hintSlice';

export const getLocal = createAsyncThunk(
    'local/getLocal',
    async (lang, {dispatch,getState}) => {
        const l = lang || getState().local.lang;
        setLeng(l);
        try {
            const res = await fetchapp(urls.localeGet(),{credentials: "include"});
            if(res.ok){
                return {data: await res.json(), lang: l};
            } else throw Error('get local error')
        } catch (e){
            dispatch(addHint(e.message));
            return null;
        }
    });


export const localSlice = createSlice({
    name: 'local',
    initialState: {
        lang: null,
        data: null
    },
    reducers: {
        setLocal: (state, action) => {
            state.data = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getLocal.fulfilled, (state, action) => {
            // state.data = {...action.payload?.data};
            // state.lang = action.payload.lang;
        })

    },
})

export const {setLocal} = localSlice.actions;
export default localSlice;
