// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main_tab_list__2NT0r {
  overflow: auto;
  gap: 16px;
  scrollbar-width: "none";
  -ms-overflow-style: "none";
}

.main_tab_list__2NT0r::-webkit-scrollbar {
  display: none;
}

.main_main_content_open__0omFw {
  transition: 0.3s;
  padding: 25px 30px 0px 280px;
}

.main_main_content_closed__M9-z4 {
  transition: 0.3s;
  padding: 25px 30px 0px 110px;
}

#main_pages__OKrpn {
  transition: 0.3s;
  width: calc(100% - 70px);
  overflow: auto;
}
@media screen and (max-width: 992px) {
  #main_pages__OKrpn {
    margin: 0px 0px 0 75px;
  }
}

.main_closedSideBar__5bT9j {
  margin: 25px 30px 0 100px;
}
@media screen and (max-width: 992px) {
  .main_closedSideBar__5bT9j {
    margin: unset;
  }
}

.main_openedSideBar__1Zdxv {
  width: calc(100% - 10px);
  margin: 25px 30px 0 280px;
}
@media screen and (max-width: 992px) {
  .main_openedSideBar__1Zdxv {
    width: unset;
    margin: unset;
  }
}

#main_main__5E2o- {
  height: calc(100vh - 96px);
  transition: 0.3s;
  background-color: #fff;
  margin: 15px 0 0;
  z-index: 2;
}

.main_sidebar_open__9J0sQ {
  width: 250px;
  padding: 0 20px;
}

.main_sidebar_closed__B7x\\+a {
  width: 70px;
  padding: 0 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/styles/main.module.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,SAAA;EACA,uBAAA;EACA,0BAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,gBAAA;EACA,4BAAA;AACJ;;AAEA;EACI,gBAAA;EACA,4BAAA;AACJ;;AAEA;EACG,gBAAA;EACA,wBAAA;EACA,cAAA;AACH;AAAI;EAJJ;IAKQ,sBAAA;EAGN;AACF;;AAAA;EACI,yBAAA;AAGJ;AAFI;EAFJ;IAGQ,aAAA;EAKN;AACF;;AAFA;EACI,wBAAA;EACA,yBAAA;AAKJ;AAHI;EAJJ;IAKQ,YAAA;IACA,aAAA;EAMN;AACF;;AAHA;EACI,0BAAA;EACA,gBAAA;EACA,sBAAA;EACA,gBAAA;EACA,UAAA;AAMJ;;AAFA;EACI,YAAA;EACA,eAAA;AAKJ;;AAFA;EACI,WAAA;EACA,eAAA;AAKJ","sourcesContent":[".tab_list {\n    overflow: auto;\n    gap: 16px;\n    scrollbar-width: 'none';\n    -ms-overflow-style: 'none';\n}\n\n.tab_list::-webkit-scrollbar{\n    display: none;\n}\n\n.main_content_open {\n    transition: 0.3s;\n    padding: 25px 30px 0px 280px;\n}\n \n.main_content_closed {\n    transition: 0.3s;\n    padding: 25px 30px 0px 110px;\n}\n\n#pages {\n   transition: 0.3s;\n   width: calc(100% - 70px);\n   overflow: auto;\n    @media screen and (max-width: 992px) {\n        margin: 0px 0px 0 75px;\n    }\n}\n\n.closedSideBar {\n    margin: 25px 30px 0 100px;\n    @media screen and (max-width: 992px) { \n        margin: unset;\n    }\n}\n\n.openedSideBar {\n    width: calc(100% - 10px);\n    margin: 25px 30px 0 280px;\n\n    @media screen and (max-width: 992px) {\n        width: unset;\n        margin: unset;\n    }\n}\n\n#main {\n    height: calc(100vh - 96px);\n    transition: 0.3s;\n    background-color: #fff;\n    margin: 15px 0 0;\n    z-index: 2;\n\n}\n\n.sidebar_open {\n    width: 250px;\n    padding: 0 20px;\n  }\n  \n.sidebar_closed {\n    width: 70px;\n    padding: 0 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tab_list": `main_tab_list__2NT0r`,
	"main_content_open": `main_main_content_open__0omFw`,
	"main_content_closed": `main_main_content_closed__M9-z4`,
	"pages": `main_pages__OKrpn`,
	"closedSideBar": `main_closedSideBar__5bT9j`,
	"openedSideBar": `main_openedSideBar__1Zdxv`,
	"main": `main_main__5E2o-`,
	"sidebar_open": `main_sidebar_open__9J0sQ`,
	"sidebar_closed": `main_sidebar_closed__B7x+a`
};
export default ___CSS_LOADER_EXPORT___;
