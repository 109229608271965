import React from 'react';
import SideBarSettings from "../pages/sideBarSettings/index"
import Sidebar from '../lib/customBlocks/sideBar';
import styles from './styles/main.module.scss'
import transactions from '../static/transactions.svg'
import settings from '../static/settings.svg'
import api from '../static/api.svg'

const Main = ({ isSidebarOpen, toggleSidebar }) => {
    
    const sidebarItems = [
    { icon: transactions, name: 'Transactions' },
    { icon: settings, name: 'Settings' },
    { icon: api, name: 'API' },
    ];

    return (
        <div id={styles.main} className={`${isSidebarOpen ? styles.sidebar_open : styles.sidebar_closed} py-4 position-fixed d-flex flex-column justify-content-between`}>
            <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} sidebarItems={sidebarItems} />
            <SideBarSettings isSidebarOpen={isSidebarOpen}/>
        </div>
    )
}

export default Main;