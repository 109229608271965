// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sideBarSettings_auth__qR6G7, .sideBarSettings_logout__p2YpU {
  border-radius: 10px;
  background: rgb(247, 247, 247);
  padding: 10px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/sideBarSettings/sideBarSettings.module.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,8BAAA;EACA,aAAA;EACA,eAAA;AACJ","sourcesContent":[".auth, .logout {\n    border-radius: 10px;\n    background: rgb(247, 247, 247);\n    padding: 10px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"auth": `sideBarSettings_auth__qR6G7`,
	"logout": `sideBarSettings_logout__p2YpU`
};
export default ___CSS_LOADER_EXPORT___;
