import React, { useEffect, useState } from "react";

export default function Modal({type, isOpen, bloked, top, bottom, children, content, transfer, beforeCloseAction, afterCloseAction}){
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const close = (e) => {
        e.stopPropagation();
        if(e.target.getAttribute('name')==='close'){
            if(typeof beforeCloseAction == 'function') beforeCloseAction();
            setOpen(false);
            if(typeof afterCloseAction == 'function') afterCloseAction();
        }
    }

    return !open ? <></> : (<>
        <div
            name="close"
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                background: 'rgba(0, 0, 0, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: top? 'start': bottom? 'end': 'center',
                zIndex: 100000,
            }}
            onClick={(e)=> {if(!bloked) close(e)}}
        >
            <div
                style={{
                    maxHeight: '100vh',
                    maxWidth: '80vh',
                    zIndex: 10,
                    overflow: 'auto'
                }}
            >
                {type ? <>
                    <div className={'w-100 h-100'+ type? `bg-${type} text-dark bg-opacity-25` : ''}>
                        <div className="px-5 h-100 overwlow-auto">
                            {content ? React.cloneElement(content,{...content.props, transfer, close}): children}
                        </div>
                    </div>
                </>
                    :
                <>
                    {content ? React.cloneElement(content,{...content.props, transfer, close}): children}
                </>
                }
            </div>
        </div>
    </>)
}
